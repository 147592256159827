export default (Alpine) =>
    document.addEventListener("alpine:init", () => {
        Alpine.store("darkMode", {
            colorTitles: {
                light: "light mode",
                dark: "dark mode",
                system: "system default",
            },
            // default to light mode
            selectedMode: Alpine.$persist("light"),

            get colorModes() {
                return Object.keys(this.colorTitles);
            },

            get isDarkModeOn() {
                if (this.selectedMode === "system") {
                    return window.matchMedia("(prefers-color-scheme: dark)")
                        .matches;
                } else {
                    return this.selectedMode === "dark";
                }
            },

            get title() {
                let nextMode = this.nextColorMode();

                return "Switch to " + this.colorTitles[nextMode];
            },

            nextColorMode() {
                let nextMode =
                    (this.colorModes.indexOf(this.selectedMode) + 1) %
                    this.colorModes.length;

                return this.colorModes[nextMode];
            },

            toggleColorMode() {
                this.selectedMode = this.nextColorMode();

                this.updateDarkClass();
            },

            updateDarkClass() {
                if (this.isDarkModeOn) {
                    document.documentElement.classList.add("dark");
                } else {
                    document.documentElement.classList.remove("dark");
                }
            },
        });
    });
