export default function (Alpine) {
    Alpine.magic("modals", () => ({
        show(name, data = {}) {
            window.dispatchEvent(
                new CustomEvent("show-modal", {
                    detail: {
                        name,
                        data,
                    },
                }),
            );
        },
        update(name, data = {}) {
            window.dispatchEvent(
                new CustomEvent("update-modal", {
                    detail: {
                        name,
                        data,
                    },
                }),
            );
        },
        close(name) {
            window.dispatchEvent(
                new CustomEvent("close-modal", {
                    detail: {
                        name,
                    },
                }),
            );
        },
    }));
}
