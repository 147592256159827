export default function (Alpine) {
    Alpine.magic("notify", function () {
        let notify = (level, message, duration) => {
            window.dispatchEvent(
                new CustomEvent("notify", {
                    detail: {
                        level: level,
                        message: message,
                        duration: duration ?? 4000,
                    },
                }),
            );
        };

        return {
            alert(message, duration) {
                notify("alert", message, duration);
            },
            success(message, duration) {
                notify("success", message, duration);
            },
            info(message, duration) {
                notify("info", message, duration);
            },
        };
    });
}
