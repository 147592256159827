/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

import "./bootstrap"; // drop???

// jQuery plugins
import "jquery-migrate";

import "./functions.js";

import { Livewire, Alpine } from "@vendor/livewire/livewire/dist/livewire.esm";

import TooltipDirective from "../alpine/directives/tooltip.js";
Alpine.plugin(TooltipDirective);

import Modals from "../alpine/magic/modals.js";
Alpine.plugin(Modals);

import Notify from "../alpine/magic/notify.js";
Alpine.plugin(Notify);

import TooltipMagic from "../alpine/magic/tooltip.js";
Alpine.plugin(TooltipMagic);

import DarkMode from "../alpine/stores/darkMode.js";
Alpine.plugin(DarkMode);

Livewire.start();

/**
 * Finally, we run our app-specific global scripts.
 */

$(function () {
    $(document).foundation();

    var timer;

    $(window).on("scroll", function () {
        clearTimeout(timer);
        if (!$("html").hasClass("disable-hover")) {
            $("html").add("disable-hover");
        }

        timer = setTimeout(function () {
            $("html").removeClass("disable-hover");
        }, 500);
    });

    if (navigator.appVersion.indexOf("Win") != -1) {
        $("html").addClass("ms-windows");
    }
    if ("onpropertychange" in document && !!window.matchMedia) {
        $("html").addClass("lt-ie11");
    }
    if (!window.ActiveXObject && "ActiveXObject" in window) {
        $("html").addClass("lt-ie12");
    }

    $("p:empty").remove();

    if ($("html").hasClass("lt-ie9")) {
        ieResize();
    }
    // TODO remove?
    $('a[data-rep="sales"]').click(function () {
        if ($(this).hasClass("active")) {
            return false;
        } else {
            $("a[data-rep]").toggleClass("active");
        }

        $(".rep_details").hide();
        $('[data-rep="sales"].rep_details').show();
    });

    $('a[data-rep="tech"]').click(function () {
        if ($(this).hasClass("active")) {
            return false;
        } else {
            $("a[data-rep]").toggleClass("active");
        }

        $(".rep_details").hide();
        $('[data-rep="tech"].rep_details').show();
    });

    $(".reps").each(function () {
        $(this).find(".rep_details").first().show();
    });

    $("p").text().replace("�", "'");

    // Sidebar Accordion TODO remove?

    var sidebarNavDropdown = function (e) {
        function doTheDrop() {
            if (!$target.length == 0) {
                $parent.not(".is-current");
                $parent.siblings().find("ul").slideUp();
                $target.slideToggle();
                return false;
            } else {
                window.location.href = $this.attr("href");
            }
        }

        var $this = $(e.target),
            $parent = $this.parent(),
            $target = $parent.find("ul"),
            $eT = $(e.delegateTarget);

        if ($eT.is(".tablet-nav")) {
            if (!$(".tablet-move-right").length) {
                if (!$target.length == 0) {
                    $parent.not(".is-current");
                    $parent.siblings().find("ul").slideUp();
                    $target.slideToggle();
                    return false;
                }
            } else {
                doTheDrop();
            }
        } else {
            doTheDrop();
        }

        e.preventDefault();
    };

    $("#left_sidebar_nav_container").on(
        "click",
        "> ul > li > a",
        sidebarNavDropdown,
    );

    $(".off-canvas-list").on("click", "> li > a", sidebarNavDropdown);

    $(".tablet-nav").on("click", "> ul > li > a", function (e) {
        e.preventDefault();

        if (!$(this).parent().find("ul").length) {
            window.location.href = $(this).attr("href");
        } else {
            sidebarNavDropdown(e);
            $(".off-canvas-wrapper").addClass("tablet-move-right");
        }
    });

    $("#left_sidebar_toggle").on("click", function () {
        $(".site_container").toggleClass("shown");
    });

    $(".tablet-nav-trigger").on("click", function (e) {
        e.preventDefault();
        $(".tablet-nav").find(".is-current > ul").slideDown();
        $(".off-canvas-wrapper").addClass("tablet-move-right");
    });

    $(".exit-tablet-nav").on("click", function (e) {
        e.preventDefault();
        $(".is-current").find("ul").slideUp();
        $(".tablet-move-right").removeClass("tablet-move-right");
    });

    // jQueryUI Progress Bars

    $(".invoice").each(function () {
        var days = parseInt($(this).find(".days").text());
        $(this)
            .find(".progressbar")
            .progressbar({
                value: 31 - days,
                max: 31,
            });
    });

    // list image layout

    $(".list_item").has(".list_item_img").addClass("has_image");

    // Expandable Tables // TODO remove ?

    $("table.expandable").on(
        "click",
        "tr.tr-parent .expandable-action",
        function () {
            $(this)
                .parents("tr.tr-parent")
                .toggleClass("expanded")
                .find(".turndown .toggle-icon")
                .toggle();
            $(this)
                .parents("tr.tr-parent")
                .next(".tr-expandable")
                .toggleClass("expanded")
                .find(".expander")
                .slideToggle();
        },
    );

    if (!$(".blog-posts").length && !$(".post").length) {
        $(".recent-posts-wrapper").remove();
    }

    if ($(".post").length) {
        var recentPosts = $(".recent-posts-wrapper").remove();

        $(".post-content").after(recentPosts);
        $(".recent-posts-wrapper").after('<div class="clear"></div>');
    }

    if ($(".blog-posts").length) {
        var recentPosts = $(".recent-posts-wrapper").remove();

        $(".blog-posts").after(recentPosts);
        $(".recent-posts-wrapper").after('<div class="clear"></div>');
    }

    $(".toggle-customer-topbar").on("click", function (e) {
        e.preventDefault();
        $(".secondary-nav-top-bar").find(".toggle-topbar a").trigger("click");
    });

    if ($(".post-header").length) {
        $(".post-content").prepend($(".post-header"));
    }
});
