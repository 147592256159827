window.number_format = function (number, decimals, dec_point, thousands_sep) {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return "" + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
};

window.ieResize = function () {
    var legacyIeWidth = $(window).width() - 200;

    $("#right_panel").width(legacyIeWidth);

    $("#right_panel").css("min-height", $(window).height());
};

window.getUrgency = function (value) {
    var result;

    switch (true) {
        case value <= 7:
            result = "red";
            break;

        case value >= 8 && value <= 30:
            result = "yellow";
            break;

        case value >= 30 && value <= 10000:
            result = "green";
            break;

        default:
            result = "red";
            break;
    }

    return result;
};

/**
 * Include legacy ajax page loading (from Engine)
 */
window.listreorder = function (neworder, asc) {
    listorder = neworder;
    listasc = asc;
    page = 1;
    loadlist();
};

window.nextpage = function () {
    if (!loadinglist) {
        page = page + 1;
        loadlist();
    }
};

window.prevpage = function () {
    if (!loadinglist) {
        page = page - 1;
        loadlist();
    }
};

window.loadpage = function (newpage) {
    if (!loadinglist) {
        page = parseInt(newpage);
        loadlist();
    }
};

window.gotopage = function () {
    var curpage = document.getElementById("curpage");

    if (!curpage) {
        return;
    }

    if (curpage.value <= 0) {
        curpage.value = page;
        return;
    }

    if (parseInt(curpage.value) > pages) {
        curpage.value = page;
        return;
    }

    if (!loadinglist) {
        page = curpage.value;
        loadlist();
    }
};

window.switch_tab = function (field, data) {
    $("#" + field).val(data);
    loadlist();
};

window.searchlist = function () {
    page = 1;
    loadlist();
};

window.loadlist = function () {
    if (!loadinglist) {
        loadinglist = true;
        var parameters = "getlist=1";
        $(".searchfield").each(function (index) {
            if ($(this).is(":checkbox")) {
                if ($(this).is(":checked")) {
                    parameters +=
                        "&" + $(this).attr("name") + "=" + $(this).val();
                }
            } else {
                parameters += "&" + $(this).attr("name") + "=" + $(this).val();
            }
        });

        $.ajax({
            type: "POST",
            url: url,
            data:
                parameters +
                "&showsearch=1&order=" +
                listorder +
                "&asc=" +
                listasc +
                "&page=" +
                page,
            success: function (text) {
                // clear the table
                $(".results").html("");

                // show the results
                $(".results").html(text);

                var resultsDiv = $("#page_content_container").offset();
                if (resultsDiv != null) {
                    window.scroll(0, resultsDiv.top - 10);
                }

                pages = text.split("<!--PAGECOUNT|")[1].split("-->")[0];
            },
            error: function (jqXHR, err, errorThrown) {
                $(".results").html("Unable to fetch list: " + errorThrown);
            },
        });

        loadinglist = false;
    }

    return false;
};

window.checkboxStatus = function (target, state) {
    var checked = $(target).is(":checked");

    if (checked == state) {
        $(target)
            .parent()
            .parent()
            .animate({ backgroundColor: "#ffffff" }, 400); //White
    } else if (checked) {
        $(target)
            .parent()
            .parent()
            .animate({ backgroundColor: "#def4d3" }, 400); //Green
    } else {
        $(target)
            .parent()
            .parent()
            .animate({ backgroundColor: "#f9e5e5" }, 400); //Red
    }
};
