import tippy from "tippy.js";

export default function (Alpine) {
    Alpine.magic("tooltip", (el) => (message) => {
        let instance = tippy(el, { content: message, trigger: "manual" });

        instance.show();

        setTimeout(() => {
            instance.hide();

            setTimeout(() => instance.destroy(), 150);
        }, 2000);
    });
}
